import './App.css';
import Wallet from './components/connectWallet/Wallet';

function App() {
  return (
    <div className="App">
      <Wallet></Wallet>
    </div>
  );
}

export default App;
